import { PeriodTypes } from './types'

type CalculateStorageParams = {
  length: number
  width: number
  height: number
}

const IN_TO_FT_CONST = 1728
const JAN_SEP_PERIOD_RATE = 0.75
const OCT_DEC_PERIOD_RATE = 1.88

export const calculateStorage = ({ length, width, height }: CalculateStorageParams) => {
  const volume = length * width * height
  const cubicFeet = volume / IN_TO_FT_CONST

  return {
    [PeriodTypes.ninety_days]: Number(cubicFeet * JAN_SEP_PERIOD_RATE * 3),
    [PeriodTypes.ninety_days_peak]: Number(cubicFeet * OCT_DEC_PERIOD_RATE * 3),
  }
}
