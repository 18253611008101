import React, { useEffect, useState } from 'react'
import { TreeSelect, Form, InputNumber, Flex, Checkbox, Radio, RadioChangeEvent } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { Category, FeeEntry, FeeType } from '@r1-oas/billing-calculator'
import { Collapse } from '../Collapse'
import { Typography } from '../Typography'
import { billingCalculatorApi } from '../../api/billing-calculator'
import { DimensionValueTypes, PeriodTypes, ProductForm } from './types'
import { validateProduct } from './validators'
import { calculateStorage } from './calculateStorage'
import './styles.css'

const DimensionSuffix = ({ children }: { children: React.ReactNode }) => (
  <div className="flex items-center justify-center bg-transparent px-3 py-0.5 text-xs text-[#16293F]">
    {children}
  </div>
)
const ErrorText = ({ children }: { children: React.ReactNode }) => (
  <div className="py-1.5 text-xs text-[#F44336]">{children}</div>
)

type TreeCategory = Category & { selectable: boolean; children: TreeCategory[] }

const makeCategoriesSelectable = (categories: Category[]): TreeCategory[] =>
  categories.map(category => ({
    ...category,
    selectable: category.children.length === 0,
    children: makeCategoriesSelectable(category.children),
  }))

export const FeesCalculator = () => {
  const [categories, setCategories] = useState<TreeCategory[]>([])
  const [state, setState] = useState<ProductForm>({
    category: undefined,
    dimValue: {
      length: null,
      width: null,
      height: null,
    },
    vas: false,
  })
  const [period, setPeriod] = useState<PeriodTypes>(PeriodTypes.ninety_days)
  const [fees, setFees] = useState<FeeEntry[]>([])

  useEffect(() => {
    const fetchCategories = async () => {
      await billingCalculatorApi.getCategoryTree().then(res => {
        if (res.success) {
          const treeCategories = makeCategoriesSelectable(res.body.rootCategories)
          setCategories(treeCategories)
        }
      })
    }

    fetchCategories()
  }, [])

  useEffect(() => {
    const calculateFees = async () => {
      if (!state.category || !Object.values(state.dimValue).every(Boolean)) {
        return
      }

      await billingCalculatorApi
        .calculateFees({
          body: {
            categoryId: state.category,
            length: Number(state.dimValue[DimensionValueTypes.L]),
            width: Number(state.dimValue[DimensionValueTypes.W]),
            height: Number(state.dimValue[DimensionValueTypes.H]),
          },
        })
        .then(res => {
          if (res.success) {
            setFees(res.body.fees)
          }
        })
    }

    calculateFees()
  }, [state.category, state.dimValue])

  const onChangeInput = (value: string | null) => (elem: DimensionValueTypes) =>
    setState(prev => ({
      ...prev,
      dimValue: { ...prev.dimValue, [elem]: value },
    }))

  const onChangeVASCheckbox = (event: CheckboxChangeEvent) =>
    setState(prev => ({
      ...prev,
      vas: event.target.checked,
    }))
  const onChangePeriod = (event: RadioChangeEvent) => {
    setPeriod(event.target.value as PeriodTypes)
  }
  const onChangeCategory = (category?: number) => {
    if (!category) {
      setFees([])
    }
    setState(prev => ({ ...prev, category }))
  }

  const isInitalDimState =
    state.dimValue[DimensionValueTypes.H] === null &&
    state.dimValue[DimensionValueTypes.L] === null &&
    state.dimValue[DimensionValueTypes.W] === null
  const { dimensionsError, vasDimensionsError, nonCalculatable } = validateProduct(state)

  const storage = calculateStorage({
    length: Number(state.dimValue[DimensionValueTypes.L]),
    width: Number(state.dimValue[DimensionValueTypes.W]),
    height: Number(state.dimValue[DimensionValueTypes.H]),
  })
  const chosenStorageValue =
    period === PeriodTypes.ninety_days
      ? storage[PeriodTypes.ninety_days]
      : storage[PeriodTypes.ninety_days_peak]

  const supplyChainFee = fees.find(fee => fee.feeType === FeeType.SupplyChainFee)
  const vasFee = fees.find(fee => fee.feeType === FeeType.VasFee)

  return (
    <div className="mt-5 flex max-[890px]:flex-col">
      {/* Left Section */}
      <Form className="flex grow flex-col items-start p-10 ring-1 ring-gray-200 min-[890px]:w-1/2 min-[890px]:rounded-l-3xl max-[890px]:rounded-t-3xl">
        <Typography.H5 className="mb-3">Dimensions</Typography.H5>
        <Flex align="center" className="max-[890px]:flex-col max-[890px]:gap-4 w-full">
          <Form.Item className="mb-0 w-full">
            <InputNumber
              size="large"
              inputMode="decimal"
              style={{ minWidth: '100px' }}
              className="input-number-custom-right w-full"
              value={state.dimValue[DimensionValueTypes.L]}
              addonAfter={<DimensionSuffix>L</DimensionSuffix>}
              onChange={val => onChangeInput(val)(DimensionValueTypes.L)}
            />
          </Form.Item>
          <Form.Item className="mb-0 w-full">
            <InputNumber
              size="large"
              inputMode="decimal"
              style={{ minWidth: '100px' }}
              className="input-number-custom-both w-full"
              value={state.dimValue[DimensionValueTypes.W]}
              addonAfter={<DimensionSuffix>W</DimensionSuffix>}
              onChange={val => onChangeInput(val)(DimensionValueTypes.W)}
            />
          </Form.Item>
          <Form.Item className="mb-0 w-full">
            <InputNumber
              size="large"
              inputMode="decimal"
              style={{ minWidth: '100px' }}
              className="input-number-custom-left w-full"
              value={state.dimValue[DimensionValueTypes.H]}
              addonAfter={<DimensionSuffix>H</DimensionSuffix>}
              onChange={val => onChangeInput(val)(DimensionValueTypes.H)}
            />
          </Form.Item>
          <DimensionSuffix>in.</DimensionSuffix>
        </Flex>
        <ErrorText>{isInitalDimState ? null : dimensionsError}</ErrorText>
        <div className="divider mt-3" />
        <Typography.H5 className="mb-3 mt-3">Category</Typography.H5>
        <TreeSelect
          showSearch
          allowClear
          size="large"
          className="w-full"
          treeNodeFilterProp="title"
          fieldNames={{ label: 'categoryName', value: 'categoryId' }}
          value={state.category}
          treeData={categories}
          onChange={onChangeCategory}
        />
        <div className="divider mt-6" />
        <div className="flex flex-col items-start">
          <Checkbox
            className="mt-6"
            disabled={!state.category}
            checked={state.vas}
            onChange={onChangeVASCheckbox}
          >
            Value-Added Services
          </Checkbox>
        </div>
        <ErrorText>{`${vasDimensionsError || ''}`}</ErrorText>
      </Form>

      {/* Right Section */}
      <div className="flex flex-col justify-between bg-primary-navy-dark pt-12 ring-1 ring-gray-900 min-[890px]:w-1/2 min-[890px]:rounded-r-3xl max-[890px]:rounded-b-3xl">
        <div className="flex flex-col px-12">
          <Typography.H5 className="mb-3 text-white">Recurring Fees</Typography.H5>
          <Collapse
            target={
              <div className="flex grow justify-between text-xl text-inherit">
                <div>Storage</div>
                <div className="font-bold">
                  {nonCalculatable ? 'N/A' : `$${chosenStorageValue.toFixed(2)}`}
                </div>
              </div>
            }
          >
            <Radio.Group value={period} className="w-full" onChange={onChangePeriod}>
              <div className="ml-9 mt-3 flex grow flex-col items-start">
                <div className="flex w-full justify-between">
                  <Radio value={PeriodTypes.ninety_days} className="text-white">
                    90 days storage fee
                  </Radio>
                  <div className="text-xl font-bold text-white">
                    {nonCalculatable ? 'N/A' : `$${storage[PeriodTypes.ninety_days].toFixed(2)}`}
                  </div>
                </div>
                <div className="my-2" />
                <div className="flex w-full justify-between">
                  <Radio value={PeriodTypes.ninety_days_peak} className="text-white">
                    90 days peak season storage fee (applicable Oct-Dec)
                  </Radio>
                  <div className="text-xl font-bold text-white">
                    {nonCalculatable
                      ? 'N/A'
                      : `$${storage[PeriodTypes.ninety_days_peak].toFixed(2)}`}
                  </div>
                </div>
              </div>
            </Radio.Group>
          </Collapse>
          <Typography.H5 className="mt-10 mb-3 text-white">One-Time Fees</Typography.H5>
          <div className="flex grow justify-between text-xl text-white py-5 border-t border-b border-slate-600">
            <div>Supply Chain Services</div>
            <div className="font-bold">
              {nonCalculatable ? 'N/A' : `$${supplyChainFee?.cost ?? 0}`}
            </div>
          </div>
          <div className="flex grow justify-between text-xl text-white py-5 border-b border-slate-600">
            <div>Value-Added Services</div>
            <div className="font-bold">{!state.vas ? 'N/A' : `$${vasFee?.cost ?? 0}`}</div>
          </div>

          <div className="mt-3 text-white text-xs">
            For reCommerce, a 10% fee will apply towards each sale&apos;s final price plus any
            applicable marketplace fees. See full list of fees
          </div>
        </div>
        <div className="bg-primary-dark mt-5 flex gap-12 rounded-br-3xl p-12 justify-center">
          <div className="flex flex-col">
            <p className="font-bold text-white">Total one-time fees estimate</p>
            <div className="flex items-center justify-center">
              <Typography.H2 className="text-white">
                {nonCalculatable
                  ? 'N/A'
                  : `$${(
                      (!state.vas ? 0 : vasFee?.cost ?? 0) +
                      (supplyChainFee?.cost ?? 0) +
                      chosenStorageValue
                    ).toFixed(2)}`}
              </Typography.H2>
              <p className="ml-3 text-white">per unit</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
